<template >
  <div> 
    <TopNav :patientName="patientName" />
    <div v-for="message in data.responseData" :key="message.id" class="card mb-3" :data-id="message.id">
      <div v-for="message in data.responseData" :key="message.id" class="card mb-3">
        <div class="card-body">
          <div :class="['message-body', message.isUser ? 'message-color-user' : 'message-color-sofia', 'text-white', 'p-3', 'rounded']">
            <p>{{ message.message }}</p>
          <p class="timestamp">{{ new Date(message.created_at).toLocaleString() }}</p>
            <div v-if="!message.isUser" class="message-actions d-flex gap-2 mt-2">
              <button @click="toggleSpeak('TTS', message.message)" class="btn-color">
                <!-- <i class="fas fa-volume-up"></i>-->
                 <i :class="speakIcon"></i> 
              </button>
              <button @click="copyToClipboard(message.message)" class="btn-color">
                <i class="fas fa-copy"></i>
              </button>
              <button @click="sendPdf(message.message)" class="btn-color">
                <i class="fas fa-file-pdf"></i>
              </button>
              <button @click="shareMessage(message.message)" class="btn-color">
                <i class="fas fa-share-alt"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="button-container d-flex flex-wrap gap-2 justify-content-center  fixed-bottom bg-light py-4 col-12 col-md-10  ">
      <button @click="handleButtonClick('SOAP')" class="btn btn-info text-white">SOAP</button>
      <button @click="handleButtonClick('Billing Code')" class="btn btn-info text-white">Billing Code</button>
      <div class="dropup">
    <button class="btn btn-white" @click="toggleDropdown">
      <i class="fa fa-ellipsis-v"></i>
    </button>
    <ul v-if="showDropdown" class="dropup-menu">
      <li><button @click="handleButtonClick('Summary')" class="btn btn-info text-white">Summary</button></li>
      <li><button @click="handleButtonClick('Transcription')" class="btn btn-info text-white">Transcription</button></li>
      <li><button @click="handleButtonClick('Dialog')" class="btn btn-info text-white">Dialog</button></li>
      <li><button @click="handleButtonClick('Prescription Order')" class="btn btn-info text-white">Prescription Order</button></li>
    </ul>
  </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted ,computed,watch, nextTick } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { useRecorder } from '../utils/useRecord'; 
import { useWebSocket } from '../utils/useWebSocket'; 
import { useToast } from 'vue-toastification';
import {setupJWTInterceptor} from '@/services/axios';
import TopNav from '../layouts/topNav.vue'

const JWTinterceptor = setupJWTInterceptor();
const { speakText,stopSpeaking } = useRecorder('en');
const store = useStore();
const route = useRoute();
const topicId = ref(route.params.chatId); 
const data = ref({ responseData: [] }); 
const loading = ref(true);
const text = ref(''); 
const dialogId ="2";
const token = localStorage.getItem('token')
const patientName = computed(() => store.state.patientName);
const wsUrl  = `${process.env.VUE_APP_SOFA_WS}chat/${topicId.value}/${token}/${dialogId}/${patientName.value}`;
const sentMessages = ref([]); 
const receivedResponses = ref([]); 
const showDropdown = ref(false)
const toast = useToast();
const isSpeaking = ref(false);
const chatContainer = ref(null);
const speakIcon = ref('fa-solid fa-volume-up');
// let interval = null;

const handleWebSocketMessage = (receivedData) => {
  if (receivedData && receivedData.message) {
    const receivedMessage = {
      id: new Date().getTime(), 
      message: receivedData.responseData.message, 
      created_at: new Date().toISOString(), 
      isUser: false,
    };

    if (data.value && data.value.responseData) { 
      data.value.responseData.push(receivedMessage); 
    } else {
      data.value = {
        responseData: [receivedMessage],
      };
    }
    console.log('Message received via WebSocket:', receivedMessage); 
    receivedResponses.value.push(receivedMessage.message);
  } else {
    console.log('Received unrecognized WebSocket message:', receivedData); 
  }
};

const { sendMessage } = useWebSocket(wsUrl, handleWebSocketMessage); 

const getTopicChat = async (topicId) => {

  try {
    const response = await JWTinterceptor.get(`${process.env.VUE_APP_SOFA_API}chat/${topicId}/messages`);
    data.value = response.data; 
    console.log('Response received:', response.data); 
    receivedResponses.value.push(JSON.stringify(response.data.responseData.message)); 
  } catch (e) {
    console.error('Error fetching chat data:', e.message);
    toast.error('Error fetching chat data:', e.message);
  } finally {
    loading.value = false;
  }
};

const sendMsg = async (msg) => {
  if (msg.trim().length > 0) {
    const messagePayload = {
      message: msg.trim(),
      is_draft: 0,
      category_id: msg.trim(),
      chat_id: topicId.value || 0, 
      message_id: '12',
      created_at: new Date().toISOString(), 
      isUser: true, 
    };
    
    sendMessage(messagePayload); 

    if (data.value && data.value.responseData) {
      data.value.responseData.push({
        id: new Date().getTime(), 
        message: messagePayload.message,
        created_at: messagePayload.created_at,
        isUser: messagePayload.isUser,
      });
    } else {
      data.value = {
        responseData: [
          {
            id: new Date().getTime(), 
            message: messagePayload.message,
            created_at: messagePayload.created_at,
            isUser: messagePayload.isUser,
          }
        ]
      };
    }
    console.log('Message sent:', messagePayload); 
    sentMessages.value.push(messagePayload.message);
  }
};


const sendPdf = async (msg) => {
  try {
    // const result = await JWTinterceptor.post(`${process.env.VUE_APP_SOFA_API}send_mail/${topicId}/`, {
    //   file_type: "pdf",
    // });
    const result = await JWTinterceptor.post(`${process.env.VUE_APP_SOFA_API}send_mail`, {
      // file_type: "pdf",
        message:msg,
    });
    toast.success("Mail Sent successfully");
    console.log('PDF send request successful:', result); 
    return result;
  } catch (e) {
    toast.error(`Error: ${e.message}`);
    console.error('Error sending PDF:', e.message); 
  }
};

const copyToClipboard = async (text) => {
  try {
    await navigator.clipboard.writeText(text); 
    toast.success("Copied to Clipboard");
    console.log('Text copied to clipboard:', text); 
  } catch (err) {
    toast.error("Failed to copy text: ", err);
    console.error("Failed to copy text: ", err); 
  }
};

const toggleSpeak = async (action, text) => {
  if (isSpeaking.value) {
    stopSpeaking();
    isSpeaking.value = false; 
    speakIcon.value = 'fa-solid fa-volume-up'; 
  } else if (action === 'TTS') {
    await speakText(text);
    isSpeaking.value = true; 
    speakIcon.value = 'fa-solid fa-pause'; 
  }
};

const shareMessage = (message) => {
  if (navigator.share) {
    navigator.share({
      title: 'Shared Message',
      text: message,
    }).then(() => {
      console.log('Share was successful.')
      toast.success('Share was successful.');
    })
      .catch((error) => {
        console.error('Sharing failed:', error)
        toast.error('Sharing failed:', error);

      });
  } else {
    console.error('Sharing is not supported on this browser.');
    toast.error('Sharing is not supported on this browser.');
  }
};

// const handleButtonClick = (buttonText) => {
//   text.value = buttonText;

//   if (buttonText === "Transcription") {
//     const firstMessageElement = document.querySelector('.card');
//     if (firstMessageElement) {
//       firstMessageElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
//     }
//     console.log('Scrolled to the first message for Transcription');
//     return;
//   }

//   const existingMessage = data.value.responseData.find(
//     (msg) => msg.message === buttonText
//   );

//   if (existingMessage) {
//     console.log(existingMessage.id)
//     const messageElement = document.querySelector(
//       `.card[data-id="${existingMessage.id}"]`
//     );

//     if (messageElement) {
//       messageElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
//     }
    
//     console.log('Message found and scrolled to:', buttonText);
//   } else {
//     sendMsg(buttonText);
//     console.log('Button clicked, message sent:', buttonText);
//   }
// };
const handleButtonClick = (buttonText) => {
  text.value = buttonText;

  if (buttonText === "Transcription") {
    const firstMessageElement = document.querySelector('.card');
    if (firstMessageElement) {
      firstMessageElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
    console.log('Scrolled to the first message for Transcription');
    return;
  }

  const existingMessage = data.value.responseData.find(
    (msg) => msg.message === buttonText
  );

  if (existingMessage) {
    console.log('Found existing message with id:', existingMessage.id);
    const messageElement = document.querySelector(
      `.card[data-id="${existingMessage.id}"]`
    );

    if (messageElement) {
      messageElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      console.log('Scrolled to message with id:', existingMessage.id);
    } else {
      console.error('Message element with id', existingMessage.id, 'not found.');
    }
  } else {
    sendMsg(buttonText);
    console.log('Button clicked, message sent:', buttonText);
  }
};


const scrollToBottom = () => {
  nextTick(() => {
    if (chatContainer.value) {
      console.log('Scrolling to bottom:', chatContainer.value.scrollHeight);
      chatContainer.value.scrollTop = chatContainer.value.scrollHeight;
    } else {
      console.error('chatContainer is null');
    }
  });
};

watch(() => route.params.chatId, (newId) => {
  topicId.value = newId
  getTopicChat(newId)
})
watch(data, (newVal, oldVal) => {
  if (newVal.responseData.length !== oldVal.responseData.length) {
    scrollToBottom();
  }
});

onMounted(() => {
  if (topicId.value && token) {
    getTopicChat(topicId.value);
  } else {
    toast.error('Chat ID or Access Token is missing');
    console.error('Chat ID or Access Token is missing'); 
  }
  scrollToBottom();
});
const toggleDropdown = () => {
  showDropdown.value = !showDropdown.value
}
</script>

<style scoped>
.card {
  background-color: #f8f9fa;
}
.timestamp {
  font-size: 0.85rem;
  color: white;
}
.message-body {
  font-size: 1rem;
}
.button-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  /* width: 75%; */
}
.message-color-user{
  background-color: #1f86ae;
}
.message-color-sofia .timestamp, .message-color-sofia{
  background-color: #d1f8e5;
  color: #383939 !important;
}
.btn-color{
  color: #2481ad;
  background-color: transparent;
  border: none;
  font-size:1.7rem;
  margin-right: 20px;
}
.fixed-bottom{
  left:unset !important;
}
.dropdown-menu-up {
  top: auto;
  bottom: 100%;
  left: 0;
  transform: translateY(-10px);
  position: absolute;
  z-index: 1000;
}
ul{
  list-style-type: none;
  padding: 5px;
}
ul button{
  margin-bottom: 5px;
}
.dropup-menu {
  top: auto;
  bottom: 100%;
  left: 0;
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}
/* .nav-header{
    background-color: #1f86ae;
} */
</style>

